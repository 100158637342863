import { IOProps } from 'react-compose-io'
import { ControleComissaoPedidoClassificacaoIO } from './ControleComissaoPedidoClassificacao.IO'
import { DetalhesClassificacaoTabela } from '../../components/DetalhesClassificacaoTabela'

type ControleComissaoPedidoClassificacaoProps = {}

export function ControleComissaoPedidoClassificacaoView({ _io }: IOProps<ControleComissaoPedidoClassificacaoIO, ControleComissaoPedidoClassificacaoProps>) {
  return (
    <DetalhesClassificacaoTabela
      titulo="Detalhes de comissao pedido"
      managerAtualizaDetalhesFuctions={_io.managerAtualizaDetalhesFuctions}
      managerGerarRelatorioDetalhesFunction={_io.managerGerarRelatorioDetalhesFunction}
      managerObterDetalhesFunction={_io.managerObterDetalhesFunction}
    />
  )
}
