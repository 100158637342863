import { IOProps } from 'react-compose-io'
import { ControleComissaoHistoricoIO } from './ControleComissaoHistorico.IO'
import { SelecaoParceiroToDashboard } from '../../components/SelecaoParceiroToDashboard'
import Typography from '@material-ui/core/Typography'

export type ControleComissaoHistoricoProps = {}

export function ControleComissaoHistoricoView({ _io }: IOProps<ControleComissaoHistoricoIO, ControleComissaoHistoricoProps>) {
  return (
    <>
      <Typography variant="h5">Historico controle de comissao pedido</Typography>
      <SelecaoParceiroToDashboard
        managerAtualizarFunction={_io.managerAtualizarFunction}
        managerObterFunction={_io.managerObterFunction}
        pageDashboardFunction={_io.pageDashboardFunction}
        pageDetalhesFunction={_io.pageDetalhesFunction}
        voltarPage={_io.voltarPage}
        subtitulo="Selecione o parceiro para visualizar o Historico de controle de comissao pedidos."
        textoTooltip="Quantidades e Valores dos pedidos conlcuidos ao decorer dos meses"
        tituloCabecalho="Resumo dos valores divergentes das comissões"
        planilhaPadraoTexto="Baixe a planilha padronizada antes de começar a importação."
        subtituloPlanilha="Faça o upload da planilha para fazer a atualização em massa."
        tituloPlanilha="Upload planilha de atualização em massa"
        managerProcessaPlanilha={_io.managerProcessaPlanilha}
      />
    </>
  )
}
