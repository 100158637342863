import { PAGES } from './../../router/routes'
import controleComissaoManager from '../../services/api/controleComissao/controleComissaoManager'

export function controleComissaoPedidoIO() {
  return {
    managerObterFunction: controleComissaoManager.obterResumosPedidoComissaoParceiro,
    managerAtualizarFunction: controleComissaoManager.atualizaResumosPedidosComissao,
    managerProcessaPlanilha: controleComissaoManager.atualizarStatusComissaoPedidoMassa,
    pageDashboardFunction: PAGES.comissaoPedidosParceiro,
    pageDetalhesFunction: PAGES.controleComissaoPedidoClassificacao,
    voltarPage: PAGES.comissaoPedidos
  }
}

export type ControleComissaoPedidoIO = ReturnType<typeof controleComissaoPedidoIO>
