import { IOProps } from 'react-compose-io'
import { Grid, IconButton, FormControl, MenuItem, Select } from '@material-ui/core'
import RefreshIcon from '@material-ui/icons/Refresh'
import { customTheme } from '../../styles/customTheme'
import { SelecionarParceiro } from '../SelecionarParceiro'
import { Botao } from '../Botao'
import { Tipografia } from '../Tipografia'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { Controller } from 'react-hook-form'
import { CabecalhoExportarRelatorio } from '../CabecalhoExportarRelatorio'
import { GraficoValorQuantidadeMultiplo } from '../GraficoValorQuantidadeMultiplo'
import { ResumosPedidoComissaoParceiro } from '../../types/controleComissaoTypes'
import { SelecaoParceiroToDashboardIO } from './SelecaoParceiroToDashboard.IO'
import { UploadPlanilhaRegularizacao } from '../UploadPlanilhaRegularizacao'
import { ModalSelecaoParceiroDashboardMassa } from '../ModalSelecaoParceiroDashboardMassa'

export type SelecaoParceiroToDashboardProps = {
  managerObterFunction: (parceiroId: number) => Promise<ResumosPedidoComissaoParceiro>
  managerAtualizarFunction: (parceiroId: number) => Promise<number>
  managerProcessaPlanilha: (parceiroId: number, arquivo: FormData) => Promise<boolean>
  pageDashboardFunction: (parceiroId: number) => string
  pageDetalhesFunction: (parceiroId: number) => string
  voltarPage: string
  subtitulo: string
  textoTooltip: string
  tituloCabecalho: string
  planilhaPadraoTexto: string
  tituloPlanilha: string
  subtituloPlanilha: string
  planilhaPadraoUrl?: string
}

export function SelecaoParceiroToDashboardView({
  _io,
  subtitulo,
  textoTooltip,
  tituloCabecalho,
  planilhaPadraoTexto,
  tituloPlanilha,
  subtituloPlanilha
}: IOProps<SelecaoParceiroToDashboardIO, SelecaoParceiroToDashboardProps>) {
  const { Container, Avatar, CabecalhoContainer, ContainerButtons, Form, Box, UploadContainer } = _io.styles

  return (
    <>
      {_io.parceiroSelecionado == 0 ? (
        <SelecionarParceiro handleConfirmar={_io.handleConfirmar} parceiros={_io.parceiros} subtitulo={subtitulo} />
      ) : (
        <Container>
          <Grid container alignItems="center" justifyContent="space-between" spacing={2}>
            <Grid item>
              <CabecalhoContainer>
                <IconButton onClick={_io.handleIconeVoltar}>
                  <ArrowBackIcon color="disabled" />
                </IconButton>
                <Avatar src={_io.parceiroLogo} />
                <Tipografia variant="h6" fontWeight="500" colorText={customTheme.colors.grey23}>
                  {_io.parceiroNome}
                </Tipografia>
                <Form>
                  <Controller
                    render={({ field }) => (
                      <FormControl variant="standard" size="small" fullWidth>
                        <Select {...field} label="meses">
                          <MenuItem value={3}>Últimos 3 meses</MenuItem>
                          <MenuItem value={6}>Últimos 6 meses</MenuItem>
                        </Select>
                      </FormControl>
                    )}
                    name="meses"
                    control={_io.control}
                  />
                </Form>
              </CabecalhoContainer>
            </Grid>
            <ContainerButtons>
              <Grid item>
                <Botao
                  textoBotao="Atualizar dados"
                  cor="primaria"
                  type="submit"
                  estilo="outlined"
                  startIcon={<RefreshIcon />}
                  width="280px"
                  onClick={_io.handleAtualizarParceiro}
                />
                <Tipografia variant="body2" colorText={customTheme.colors.cinza07}>
                  {`Última atualização: ${_io.dataAtualizacao}`}
                </Tipografia>
              </Grid>
            </ContainerButtons>
          </Grid>
          <Box>
            <CabecalhoExportarRelatorio url={_io.urlRelatorio} textoTooltip={textoTooltip} tituloCabecalho={tituloCabecalho} />
            <GraficoValorQuantidadeMultiplo
              valoresMensais={_io.dadosGrafico.valor}
              quantidadesMensais={_io.dadosGrafico.quantidade}
              legendaEixoYValor="Mil"
              legendaEixoYQuantidade="Quantidade"
              legendaMeses={_io.dadosGrafico.nomeMeses}
              descricaoValor={`Total dos valores de comissao`}
              valor={_io.resumoValor}
              descricaoQuantidade={`Quantidade total de pedidos com divergencia de comissao`}
              quantidade={_io.resumoQuatidade}
              handleVerMaisDetalhesPedidoBotao={_io.handleVerMaisDetalhesPedidoBotao}
            />
          </Box>
          <UploadContainer>
            <UploadPlanilhaRegularizacao
              validarPlanilha={_io.processarPlanilha}
              subtitulo={subtituloPlanilha}
              titulo={tituloPlanilha}
              planilhaPadraoTexto={planilhaPadraoTexto}
              planilhaPadraoUrl={process.env.REACT_APP_PLANILHA_ATUALIZACAO_STATUS_COMISSAO_PEDIDO_MASSA}
              setArquivoId={_io.setArquivoId}
            />
          </UploadContainer>
          <ModalSelecaoParceiroDashboardMassa
            abrirModalSelecaoParceiro={_io.abrirModalAtualizarStatus}
            handleFecharModalSelecaoParceiro={_io.handleFecharModalSelecaoParceiro}
            handleConfirmarDados={() => _io.handleFecharModalSelecaoParceiro()}
            mensagemErroUploadPlanilha={_io.mensagemErroUploadPlanilha}
          />
        </Container>
      )}
    </>
  )
}
