import {
  ControleComissaoStatusForm,
  ControleComissaoPaginado,
  ControleComissaoPaginadoRequest,
  ControleComissaoStatusResumo,
  AdicionarChamadoForm,
  EditarChamadoForm,
  ControleComissaoChamados,
  ObterControleComissaoPedidosResponse,
  ObterControleComissaoPedidosQuery,
  AtualizaPedidosComissaoRequest,
  ControleComissaoPedidoResponse,
  ResumosPedidoComissaoParceiro
} from '../../../types/controleComissaoTypes'
import controleComissaoService from './controleComissaoService'

const buscarControleComissaoStatusResumo = async (): Promise<ControleComissaoStatusResumo[]> => controleComissaoService.buscarControleComissaoStatusResumo()

const buscarControleComissaoResumo = async (controleComissaoPaginadoRequest: ControleComissaoPaginadoRequest): Promise<ControleComissaoPaginado> =>
  controleComissaoService.buscarControleComissaoResumo(controleComissaoPaginadoRequest)

const editarControleComissaoResumo = async (id: string, body: ControleComissaoStatusForm): Promise<boolean> =>
  controleComissaoService.editarControleComissaoResumo(id, body)

const buscarControleComissaoChamados = async (documentId: string): Promise<ControleComissaoChamados[]> =>
  controleComissaoService.buscarControleComissaoChamados(documentId)

const adicionarNovoChamado = async (documentId: string, body: AdicionarChamadoForm): Promise<boolean> =>
  controleComissaoService.adicionarNovoChamado(documentId, body)

const editarChamado = async (documentId: string, chamadoId: string, body: EditarChamadoForm): Promise<boolean> =>
  controleComissaoService.editarChamado(documentId, chamadoId, body)

const obterControleComissaoPedidos = async (
  obterControleComissaoPedidosQuery: ObterControleComissaoPedidosQuery,
  parceiroId: number
): Promise<ObterControleComissaoPedidosResponse> => controleComissaoService.obterControleComissaoPedidos(obterControleComissaoPedidosQuery, parceiroId)

const obterResumosPedidoComissaoParceiro = async (parceiroId: number): Promise<ResumosPedidoComissaoParceiro> =>
  controleComissaoService.obterResumosPedidoComissaoParceiro(parceiroId)

const atualizaPedidosComissao = async (body: AtualizaPedidosComissaoRequest[]): Promise<boolean> => controleComissaoService.atualizaPedidosComissao(body)

const gerarResumosControleComissaoPedidosTela = async (body: ControleComissaoPedidoResponse[]): Promise<string> =>
  controleComissaoService.gerarResumosControleComissaoPedidosTela(body)

const atualizaResumosPedidosComissao = async (parceiroId: number): Promise<number> => controleComissaoService.atualizaResumosPedidosComissao(parceiroId)

const obterHistoricoControleComissaoPedidos = async (
  obterControleComissaoPedidosQuery: ObterControleComissaoPedidosQuery,
  parceiroId: number
): Promise<ObterControleComissaoPedidosResponse> => controleComissaoService.obterHistoricoControleComissaoPedidos(obterControleComissaoPedidosQuery, parceiroId)

const obterResumosHistoricoPedidoComissaoParceiro = async (parceiroId: number): Promise<ResumosPedidoComissaoParceiro> =>
  controleComissaoService.obterResumosHistoricoPedidoComissaoParceiro(parceiroId)

const atualizaResumosHistoricoPedidosComissao = async (parceiroId: number): Promise<number> =>
  controleComissaoService.atualizaResumosHistoricoPedidosComissao(parceiroId)

const atualizarStatusComissaoPedidoMassa = async (parceiroId: number, arquivo: FormData): Promise<boolean> =>
  controleComissaoService.atualizarStatusComissaoPedidoMassa(parceiroId, arquivo)

export default {
  buscarControleComissaoResumo,
  editarControleComissaoResumo,
  buscarControleComissaoStatusResumo,
  buscarControleComissaoChamados,
  adicionarNovoChamado,
  editarChamado,
  obterControleComissaoPedidos,
  atualizaPedidosComissao,
  gerarResumosControleComissaoPedidosTela,
  obterResumosPedidoComissaoParceiro,
  atualizaResumosPedidosComissao,
  obterHistoricoControleComissaoPedidos,
  obterResumosHistoricoPedidoComissaoParceiro,
  atualizaResumosHistoricoPedidosComissao,
  atualizarStatusComissaoPedidoMassa
}
