import { IOProps } from 'react-compose-io'
import ConfirmarDialog from '../ConfirmarDialog/ConfirmarDialog'
import { InformacoesModalRegularizacaoErro } from '../InformacoesModalRegularizacaoErro/index'
import { ModalSelecaoParceiroDashboardMassaIO } from './ModalSelecaoParceiroDashboardMassa.IO'

export type ModalSelecaoParceiroDashboardMassaProps = {
  abrirModalSelecaoParceiro: boolean
  handleFecharModalSelecaoParceiro: (value: boolean | ((prevVar: boolean) => boolean)) => void
  mensagemErroUploadPlanilha: string
  handleConfirmarDados: () => void
}

export function ModalSelecaoParceiroDashboardMassaView({
  _io,
  abrirModalSelecaoParceiro,
  handleFecharModalSelecaoParceiro,
  mensagemErroUploadPlanilha,
  handleConfirmarDados
}: IOProps<ModalSelecaoParceiroDashboardMassaIO, ModalSelecaoParceiroDashboardMassaProps>) {
  const { SubtituloContainer, ContainerModal, ContainerTituloModal } = _io.styles

  return (
    <ConfirmarDialog
      tamanhoModal={'md'}
      open={abrirModalSelecaoParceiro}
      setOpen={handleFecharModalSelecaoParceiro}
      confirmarButtonLabel={'Confirmar'}
      handleConfirm={handleConfirmarDados}
      dialogTitle="Atualização"
      ocultarBotoes={Boolean(mensagemErroUploadPlanilha)}
    >
      <ContainerModal>
        {!mensagemErroUploadPlanilha ? (
          <>
            <ContainerTituloModal>
              <SubtituloContainer>
                Pedidos enviados para atualização. Isso pode levar um tempo, favor aguardar e verificar alteração na tela de pedidos!
              </SubtituloContainer>
            </ContainerTituloModal>
          </>
        ) : (
          <>
            <ContainerTituloModal>
              <SubtituloContainer>Foi encontrado um erro ao realizar a leitura da planilha. Erro:</SubtituloContainer>
            </ContainerTituloModal>
            <InformacoesModalRegularizacaoErro mensagemErroUploadPlanilha={mensagemErroUploadPlanilha} />
          </>
        )}
      </ContainerModal>
    </ConfirmarDialog>
  )
}
