import controleComissaoManager from '../../services/api/controleComissao/controleComissaoManager'

export function controleComissaoHistoricoClassificacaoIO() {
  return {
    managerObterDetalhesFunction: controleComissaoManager.obterHistoricoControleComissaoPedidos,
    managerAtualizaDetalhesFuctions: controleComissaoManager.atualizaPedidosComissao,
    managerGerarRelatorioDetalhesFunction: controleComissaoManager.gerarResumosControleComissaoPedidosTela
  }
}

export type ControleComissaoHistoricoClassificacaoIO = ReturnType<typeof controleComissaoHistoricoClassificacaoIO>
