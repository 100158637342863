import { IOProps } from 'react-compose-io'
import { ControleComissaoPedidoIO } from './ControleComissaoPedido.IO'
import { SelecaoParceiroToDashboard } from '../../components/SelecaoParceiroToDashboard'
import { Typography } from '@material-ui/core'

export type ControleComissaoPedidoProps = {}

export function ControleComissaoPedidoView({ _io }: IOProps<ControleComissaoPedidoIO, ControleComissaoPedidoProps>) {
  return (
    <>
      <Typography variant="h5">Controle de comissao de pedido</Typography>
      <SelecaoParceiroToDashboard
        managerAtualizarFunction={_io.managerAtualizarFunction}
        managerObterFunction={_io.managerObterFunction}
        pageDashboardFunction={_io.pageDashboardFunction}
        pageDetalhesFunction={_io.pageDetalhesFunction}
        voltarPage={_io.voltarPage}
        subtitulo="Selecione o parceiro para visualizar o Controle de comissao de pedido."
        textoTooltip="Quantidades e Valores dos pedidos com divergencia de comissão ao decorer dos meses"
        tituloCabecalho="Resumo dos valores divergentes das comissões"
        planilhaPadraoTexto="Baixe a planilha padronizada antes de começar a importação."
        subtituloPlanilha="Faça o upload da planilha para fazer a atualização em massa."
        tituloPlanilha="Upload planilha de atualização em massa"
        managerProcessaPlanilha={_io.managerProcessaPlanilha}
      />
    </>
  )
}
