import controleComissaoManager from '../../services/api/controleComissao/controleComissaoManager'

export function controleComissaoPedidoClassificacaoIO() {
  return {
    managerObterDetalhesFunction: controleComissaoManager.obterControleComissaoPedidos,
    managerAtualizaDetalhesFuctions: controleComissaoManager.atualizaPedidosComissao,
    managerGerarRelatorioDetalhesFunction: controleComissaoManager.gerarResumosControleComissaoPedidosTela
  }
}

export type ControleComissaoPedidoClassificacaoIO = ReturnType<typeof controleComissaoPedidoClassificacaoIO>
