import { concatenarFiltrosQuery } from '../utils/stringUtils'
import {
  AdicionarNovoParametro,
  Associcao,
  CadastrarNovoParceiro,
  Ciclos,
  ConfiguracoesGerais,
  ControleComissao,
  ControleTitulos,
  Dashboard,
  ParametrosRepasse,
  IniciarNovoCiclo,
  LancamentoAssociacao,
  Login,
  Parceiros,
  Pedidos,
  RegraTransformacao,
  RegularizacaoCiclosEfetivacao,
  RegularizacaoCiclosInformacoes,
  RegularizacaoCiclosResumo,
  RegularizacaoTitulos,
  TiposDeLancamentos,
  VisualizarContabilizacao,
  RegularizacaoPerdas,
  RegularizacaoOPs,
  ControleDebitos,
  ControleCicloPedido,
  ParametrosSelecionar,
  ParametrosCicloPedido,
  ControleDebitosRecebiveis,
  DetalhesCicloPedido,
  ControleInadimplencias,
  DetalheInadimplencia,
  DetalhesOpsEmAberto,
  ControleHistoricoPerdas,
  RelatorioConciliacao,
  DetalheDebito,
  ControleClassificacoes,
  ControleComissaoPedido,
  ControleComissaoPedidoClassificacao,
  ControleComissaoHistorico,
  ControleComissaoHistoricoClassificacao
} from '../pages'

export const PAGES = {
  inicio: '/inicio',
  login: '/login',
  pedidos: '/pedidos',
  parceiros: '/parceiros',
  parametrosRepasse: (parceiroId: string | number, abaSelecionada: string) => `/parceiros/${parceiroId}/selecionar/parametros-repasse/${abaSelecionada}`,
  selecionarParametrizacao: (parceiroId: string | number) => `/parceiros/${parceiroId}/selecionar`,
  associacaoCadastro: (parceiroId: string) => `/parceiros/${parceiroId}/selecionar/parametros-repasse/associar/cadastro-associacao`,
  associacaoEditar: (parceiroId: string, regraId: string | number) => `/parceiros/${parceiroId}/selecionar/parametros-repasse/associar/associacao/${regraId}`,
  regraTransformacaoFormCadastro: (parceiroId: string) => `/parceiros/${parceiroId}/selecionar/parametros-repasse/transformar/cadastro-transformacao`,
  regraTransformacaoFormEditar: (parceiroId: string, regraId: string) =>
    `/parceiros/${parceiroId}/selecionar/parametros-repasse/transformar/transformacao/${regraId}`,
  editarParceiroId: (parceiroId: string | number) => `/parceiros/${parceiroId}/temp/editar`,
  parametrosCicloPedido: (parceiroId: string | number) => `/parceiros/${parceiroId}/selecionar/parametros-ciclo-pedido`,
  configuracoesGerais: '/configuracoes-gerais',
  tiposLancamentos: '/tipos-lancamentos',
  ciclos: '/ciclos',
  iniciarCiclo: (cicloId: number | string) => `/ciclos/${cicloId}`,
  visualizarContabilizacaoId: (cicloId: string, tipoLancamento: string, sequenciaId: number) =>
    `/ciclos/${cicloId}/contabilizacao/${tipoLancamento}/${sequenciaId}`,
  adicionarParametro: (cicloId: string, parceiroId: number) => `/ciclos/${cicloId}/novo-parametro/${parceiroId}`,
  lancamentoNaoAssociado: (cicloId: string, lancamento: string, parceiroId: string) => `/ciclos/${cicloId}/nova-associacao/${parceiroId}/${lancamento}`,
  titulos: '/controle-titulos',
  comissao: '/controle-comissao',
  regularizacaoCiclos: '/regularizacao',
  informacaoId: (cicloId?: string | null, parceiroId?: string | null, pedidoPlanilhaId?: string | null, clienteId?: string | null) =>
    `/regularizacao/informacao${concatenarFiltrosQuery({
      cicloId: cicloId,
      parceiroId: parceiroId,
      pedidoPlanilhaId: pedidoPlanilhaId,
      clienteId: clienteId
    })}`,
  regularizacaoCiclosEfetivacaoId: (pedidoId: string, clienteId?: string) => `/regularizacao/informacao/0/0/0/${clienteId}/pedido/${pedidoId}`,
  regularizacaoTitulos: '/titulos-regularizacao',
  perdas: '/perdas-regularizacao',
  ops: '/ops-regularizacao',
  opsEmAbertoDetalhes: '/ops-regularizacao/ops-detalhes',
  debitos: '/controle-perdas/debitos',
  controleCicloPedido: '/controle-ciclo-pedido',
  controleCicloPedidoParceiro: (parceiroId: number) => `/controle-ciclo-pedido/${parceiroId}`,
  detalhesCicloPedidoParceiro: (parceiroId: number) => `/controle-ciclo-pedido/${parceiroId}/detalhes-de-pedidos`,
  debitosRecebiveis: (parceiroId?: number) => `/controle-perdas/debitos/recebiveis/${parceiroId}`,
  inadimplencias: '/controle-perdas/inadimplencia',
  detalheInadimplencia: (parceiroId: number, anomalia?: string) => `/controle-perdas/inadimplencia/detalhes/${parceiroId}/${anomalia}`,
  detalheDebito: (anomalia: string, parceiroId: number) => `/controle-perdas/debitos/recebiveis/${parceiroId}/detalhes/${anomalia}`,
  historicoPerdas: '/controle-perdas/historico',
  controleClassificacoes: '/controle-perdas/classificacoes',
  relatorioConciliacao: '/relatorios',
  comissaoPedidos: '/controle-pedidos-comissao',
  comissaoPedidosParceiro: (parceiroId: number) => `/controle-pedidos-comissao/${parceiroId}`,
  controleComissaoPedidoClassificacao: (parceiroId: number) => `/controle-pedidos-comissao/${parceiroId}/classificacao-pedidos`,
  comissaoHistorico: '/historico-controle-pedidos-comissao',
  comissaoHistoricoParceiro: (parceiroId: number) => `/historico-controle-pedidos-comissao/${parceiroId}`,
  controleComissaoHistoricoClassificacao: (parceiroId: number) => `/historico-controle-pedidos-comissao/${parceiroId}/classificacao`
}

export const ROUTES = [
  { path: '/inicio', name: 'Início', Component: Dashboard },
  { path: '/login', name: 'Login', Component: Login },
  { path: '/pedidos', name: 'Pedidos', Component: Pedidos },
  { path: '/parceiros', name: 'Parceiros', Component: Parceiros },
  { path: '/parceiros/:parceiroId/selecionar', name: 'Selecionar', Component: ParametrosSelecionar },
  { path: '/parceiros/:parceiroId/selecionar/parametros-repasse/:abaIndex', name: 'Parâmetros - Repasse do parceiro', Component: ParametrosRepasse },
  { path: '/parceiros/:parceiroId/selecionar/parametros-repasse/:abaIndex/cadastro-associacao', name: 'Cadastrar associação', Component: Associcao },
  { path: '/parceiros/:parceiroId/selecionar/parametros-repasse/:abaIndex/associacao/:regraId', name: 'Editar associação', Component: Associcao },
  {
    path: '/parceiros/:parceiroId/selecionar/parametros-repasse/:abaIndex/cadastro-transformacao',
    name: 'Cadastrar transformação',
    Component: RegraTransformacao
  },
  {
    path: '/parceiros/:parceiroId/selecionar/parametros-repasse/:abaIndex/transformacao/:regraId',
    name: 'Editar transformação',
    Component: RegraTransformacao
  },
  { path: '/parceiros/:parceiroId/:abaIndex/editar', name: 'Editar parceiro', Component: CadastrarNovoParceiro },
  { path: '/parceiros/:parceiroId/selecionar/parametros-ciclo-pedido', name: 'Parâmetros - Ciclo de pedido', Component: ParametrosCicloPedido },
  { path: '/configuracoes-gerais', name: 'Configurações gerais', Component: ConfiguracoesGerais },
  { path: '/tipos-lancamentos', name: 'Tipos lançamento e-Fácil', Component: TiposDeLancamentos },
  { path: '/ciclos', name: 'Ciclos', Component: Ciclos },
  { path: '/ciclos/:cicloId', name: 'Detalhes', Component: IniciarNovoCiclo },
  {
    path: '/ciclos/:cicloId/nova-associacao/:parceiroId/:pedidoId',
    name: 'Nova associação',
    Component: LancamentoAssociacao
  },
  {
    path: '/ciclos/:cicloId/novo-parametro/:parceiroId',
    name: 'Nova transformação',
    Component: AdicionarNovoParametro
  },
  {
    path: '/ciclos/:cicloId/contabilizacao/:tipoLancamento/:sequenciaId',
    name: 'Contabilização',
    Component: VisualizarContabilizacao
  },
  { path: '/controle-titulos', name: 'Controle de títulos', Component: ControleTitulos },
  { path: '/controle-comissao', name: 'Controle de comissão', Component: ControleComissao },
  { path: '/controle-pedidos-comissao', name: 'Selecionar Parceiros', Component: ControleComissaoPedido },
  { path: '/controle-pedidos-comissao/:parceiroId', name: 'Resumos', Component: ControleComissaoPedido },
  { path: '/controle-pedidos-comissao/:parceiroId/classificacao-pedidos', name: 'Classificação Pedidos', Component: ControleComissaoPedidoClassificacao },
  { path: '/historico-controle-pedidos-comissao', name: 'Selecionar Parceiros', Component: ControleComissaoHistorico },
  { path: '/historico-controle-pedidos-comissao/:parceiroId', name: 'Resumos', Component: ControleComissaoHistorico },
  { path: '/historico-controle-pedidos-comissao/:parceiroId/classificacao', name: 'Resumos', Component: ControleComissaoHistoricoClassificacao },
  { path: '/regularizacao', name: 'Regularização', Component: RegularizacaoCiclosResumo },
  {
    path: '/regularizacao/informacao/:cicloId?/:parceiroId?/:pedidoPlanilhaId?/:clienteId?',
    name: 'Pesquisar pedidos',
    Component: RegularizacaoCiclosInformacoes
  },
  {
    path: '/regularizacao/informacao/:cicloId?/:parceiroId?/:pedidoPlanilhaId?/:clienteId?/pedido/:pedidoId',
    name: 'Regularização OP x título',
    Component: RegularizacaoCiclosEfetivacao
  },
  { path: '/titulos-regularizacao', name: 'Regularização títulos', Component: RegularizacaoTitulos },
  { path: '/perdas-regularizacao', name: 'Regularização perdas', Component: RegularizacaoPerdas },
  { path: '/ops-regularizacao', name: "Regularização OP's", Component: RegularizacaoOPs },
  { path: '/ops-regularizacao/ops-detalhes', name: 'Detalhes das OPs', Component: DetalhesOpsEmAberto },
  { path: '/controle-perdas/debitos', name: 'Controle débitos', Component: ControleDebitos },
  { path: '/controle-perdas/inadimplencia', name: 'Controle inadimplência', Component: ControleInadimplencias },
  { path: '/controle-ciclo-pedido', name: 'Selecionar parceiro', Component: ControleCicloPedido },
  { path: '/controle-ciclo-pedido/:parceiroId', name: 'Controle de ciclo de pedidos', Component: ControleCicloPedido },
  { path: '/controle-ciclo-pedido/:parceiroId/detalhes-de-pedidos', name: 'Detalhes de pedidos', Component: DetalhesCicloPedido },
  { path: '/controle-perdas/debitos/recebiveis/:parceiroId', name: 'Recebíveis', Component: ControleDebitosRecebiveis },
  { path: '/controle-perdas/inadimplencia/detalhes/:parceiroId/:anomalia', name: 'Detalhes de pedidos', Component: DetalheInadimplencia },
  { path: '/controle-perdas/debitos/recebiveis/:parceiroId/detalhes/:anomalia', name: 'Detalhes de pedidos', Component: DetalheDebito },
  { path: '/controle-perdas/historico', name: 'Histórico de perdas', Component: ControleHistoricoPerdas },
  { path: '/relatorios', name: 'Relatórios', Component: RelatorioConciliacao },
  { path: '/controle-perdas/classificacoes', name: 'Controle de classificações', Component: ControleClassificacoes }
]

export const ABAS_DETALHES_PARCEIROS = {
  transformacao: 'transformar',
  associar: 'associar',
  gruposRegras: 'grupos-regras',
  tiposLancamentos: 'tipos-lancamentos'
}
