import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { useParams } from 'react-router-dom'
import {
  AtualizaPedidosComissaoRequest,
  ClassificacaoControleComissaoPedidos,
  ControleComissaoPedidoResponse,
  ObterControleComissaoPedidosFiltros,
  ObterControleComissaoPedidosResponse
} from '../../types/controleComissaoTypes'
import { detalhesClassificacaoTabelaStyles } from './DetalhesClassificacaoTabela.styles'
import { useConciliacaoContext } from '../../contexts/conciliacao-context'
import { CabecalhoTabelaControleComissaoPedido } from '../TabelaControleComissaoPedido/TabelaControleComissaoPedido.View'
import { Paginacao } from '../../types/tabelaTypes'
import { loadingReset, loadingUpdate } from '../../store/actions/loadingAction'
import { ItemControleComissaoPedido } from './components/ItemControleComissaoPedido'
import { comissaoPedidoChavesFromString, deControleComissaoPedidoParaChave, handleSelecao } from '../../utils/controleComissaoUtils'
import msgConstants from '../../constants/msgConstants'
import { DetalhesClassificacaoTabelaProps } from './DetalhesClassificacaoTabela.View'

const schema = yup.object({
  dataLancamentoFim: yup.string().test('valorMinimo', `Deve ser maior ou igual que 'Data de lançamento'`, function (dataLancamentoFim, form) {
    const dataInicial = '' + form.parent.dataLancamentoInicio
    const dataFinal = '' + dataLancamentoFim

    if (form.parent.dataLancamentoInicio == '' || dataLancamentoFim == '') return true

    return new Date(dataInicial) <= new Date(dataFinal)
  })
})

const classificacoes: ClassificacaoControleComissaoPedidos[] = [
  {
    name: 'Em Andamento',
    value: 2
  },
  {
    name: 'Pendente',
    value: 1
  },
  {
    name: 'Concluido',
    value: 0
  }
]

export function detalhesClassificacaoTabelaIO({
  managerObterDetalhesFunction,
  managerAtualizaDetalhesFuctions,
  managerGerarRelatorioDetalhesFunction
}: DetalhesClassificacaoTabelaProps) {
  const [selectedClassificacoesList, setSelectedClassificacoesList] = useState<string[]>([])
  const [filtroClassificacoesAberto, setFiltroClassificoesAberto] = useState<HTMLElement | null>(null)
  const [filtroPedidoAberto, setFiltroPedidoAberto] = useState<HTMLElement | null>(null)
  const [controleComissaoPedidos, setControleComissaoPedidos] = useState<ObterControleComissaoPedidosResponse>()
  const [pagina, setPagina] = useState<number>(0)
  const [modalClassificarPedidosAberto, setModalClassificarPedidosAberto] = useState<boolean>(false)
  const [linhasPorPagina, setLinhasPorPagina] = useState<number>(10)
  const [urlTela, setUrlTela] = useState<string | undefined>()
  const [relatorioLoad, setRelatorioLoad] = useState<boolean>(false)
  const { checkListSelecionados, handleCleanAllCheckList } = useConciliacaoContext()
  const { parceiroId } = useParams<{ parceiroId: string }>()
  const [parceiroSelecionado, setParceiroSelecionado] = useState<number>(Number(parceiroId ? parceiroId : 0))

  const loadingDispatch = useDispatch()

  useEffect(() => {
    setParceiroSelecionado(Number(parceiroId))
    setUrlTela(undefined)
  }, [])

  useEffect(() => {
    if (parceiroSelecionado) obterControleComissaoPedidos({ pageNumber: 0, pageSize: 10 }, parceiroSelecionado)
    setUrlTela(undefined)
  }, [parceiroSelecionado])

  const cabecalhosTabela: CabecalhoTabelaControleComissaoPedido[] = [
    {
      descricao: 'Parceiro'
    },
    {
      descricao: 'Número do pedido',
      abrirPopover: (event: React.MouseEvent<HTMLButtonElement>) => {
        setFiltroPedidoAberto(event.currentTarget)
      }
    },
    {
      descricao: 'Valor Recebido X Valor Total'
    },
    {
      descricao: 'Comissão Cobrada'
    },
    {
      descricao: 'Comissão Devida'
    },
    {
      descricao: 'Diferença'
    },
    {
      descricao: 'Classificação da Análise',
      abrirPopover: (event: React.MouseEvent<HTMLButtonElement>) => {
        setFiltroClassificoesAberto(event.currentTarget)
      }
    },
    {
      descricao: 'Data de Emissão'
    }
  ]

  const obterControleComissaoPedidos = useCallback(
    async (paginacao: Paginacao, parceiroId: number, isResetarBusca = false) => {
      loadingDispatch(loadingUpdate(true))
      await managerObterDetalhesFunction(
        {
          pageNumber: paginacao.pageNumber,
          pageSize: paginacao.pageSize,
          orderBy: paginacao.orderBy,
          orderField: paginacao.orderField,
          pedidoMkt: getValues('pedidoMkt'),
          dataLancamentoInicio: getValues('dataLancamentoInicio'),
          dataLancamentoFim: getValues('dataLancamentoFim'),
          classificacao: isResetarBusca ? [] : selectedClassificacoesList
        },
        parceiroId
      )
        .then((response: ObterControleComissaoPedidosResponse) => {
          setControleComissaoPedidos(response)
        })
        .catch((error) => toast.error(error.message))
        .finally(() => {
          loadingDispatch(loadingReset())
          setUrlTela(undefined)
        })
    },
    [selectedClassificacoesList]
  )

  const closeFiltro = () => {
    setFiltroPedidoAberto(null)
    setFiltroClassificoesAberto(null)
    handleCleanAllCheckList()
    obterControleComissaoPedidos({ pageNumber: 0, pageSize: linhasPorPagina }, parceiroSelecionado)
    setUrlTela(undefined)
  }

  const handleAplicar = () => {
    setPagina(0)
    setLinhasPorPagina(10)
    handleCleanAllCheckList()
    obterControleComissaoPedidos({ pageNumber: 0, pageSize: 10 }, parceiroSelecionado)
    setUrlTela(undefined)
  }

  const handleLimpar = () => {
    setValue('dataLancamentoInicio', '')
    setValue('dataLancamentoFim', '')
    setValue('pedidoMkt', '')
    setSelectedClassificacoesList([])
    handleCleanAllCheckList()
    obterControleComissaoPedidos({ pageNumber: 0, pageSize: 10 }, parceiroSelecionado, true)
    setUrlTela(undefined)
  }

  const {
    handleSubmit,
    control,
    formState: { errors },
    register,
    setValue,
    getValues
  } = useForm<ObterControleComissaoPedidosFiltros>({
    defaultValues: {
      dataLancamentoInicio: '',
      dataLancamentoFim: '',
      pedidoMkt: ''
    },
    resolver: yupResolver(schema)
  })

  const onMudancaPagina = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, novaPagina: number) => {
    setPagina(novaPagina)
    obterControleComissaoPedidos({ pageNumber: novaPagina + 1, pageSize: linhasPorPagina }, parceiroSelecionado)
    setUrlTela(undefined)
  }

  const onMudancaLinhasPorPagina = (event: ChangeEvent<HTMLInputElement>) => {
    setLinhasPorPagina(parseInt(event.target.value, 10))
    obterControleComissaoPedidos({ pageNumber: 0, pageSize: parseInt(event.target.value, 10) }, parceiroSelecionado)
    setUrlTela(undefined)
  }

  const renderLinhasTabela = (controleComissaoPedido: ControleComissaoPedidoResponse) => {
    const chave: string = deControleComissaoPedidoParaChave(controleComissaoPedido)
    return <ItemControleComissaoPedido key={chave} chave={chave} item={controleComissaoPedido} />
  }

  const handleClassificarPedidos = async (data: { observacao: string; classificacao: number }) => {
    const body = (checkListSelecionados as string[]).map((linha) => {
      const chaves = comissaoPedidoChavesFromString(linha)
      return {
        observacao: data.observacao,
        classificacaoStatus: data.classificacao,
        ...chaves
      } as AtualizaPedidosComissaoRequest
    })
    setModalClassificarPedidosAberto(false)
    loadingDispatch(loadingUpdate(true))
    await managerAtualizaDetalhesFuctions(body)
      .then(() => {
        toast.success(msgConstants.CONTROLE_COMISSAO.pedidoAtualizadoSucesso)
      })
      .catch((error) => {
        toast.error(error.message)
      })
      .finally(() => {
        handleAplicar()
      })
  }

  const handleRelatorioTela = async () => {
    if (!urlTela && controleComissaoPedidos?.itens) {
      setRelatorioLoad(true)
      await managerGerarRelatorioDetalhesFunction(controleComissaoPedidos?.itens)
        .then((response) => {
          setUrlTela(response)
        })
        .catch((error) => {
          toast.error(error.message)
        })
      setRelatorioLoad(false)
    }
  }

  return {
    styles: detalhesClassificacaoTabelaStyles,
    errors,
    control,
    handleSubmit,
    register,
    setValue,
    getValues,
    cabecalhosTabela,
    checkListSelecionados,
    handleCleanAllCheckList,
    controleComissaoPedidos,
    pagina,
    linhasPorPagina,
    onMudancaPagina,
    onMudancaLinhasPorPagina,
    renderLinhasTabela,
    closeFiltro,
    filtroPedidoAberto,
    handleCheckFiltroClassificacao: (classificacao: string) => {
      handleSelecao<string>(classificacao, setSelectedClassificacoesList)
    },
    isClassificacaoChecked: (classificacao: string) => selectedClassificacoesList.includes(classificacao),
    filtroClassificacoesAberto,
    classificacoes,
    modalClassificarPedidosAberto,
    fecharModal: () => setModalClassificarPedidosAberto(false),
    handleClassificarPedidos,
    abrirModalClassificarPedido: () => setModalClassificarPedidosAberto(true),
    handleLimpar,
    handleAplicar,
    urlTela,
    handleRelatorioTela,
    relatorioLoad
  }
}

export type DetalhesClassificacaoTabelaIO = ReturnType<typeof detalhesClassificacaoTabelaIO>
