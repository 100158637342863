import styled from 'styled-components'
import { ContainerSombra } from '../../components/ContainerSombra'
import TextField from '@material-ui/core/TextField/TextField'
import { DownloadRelatorio } from '../../components/DownloadRelatorio'
import { Typography } from '@material-ui/core'
import { customTheme } from '../../styles/customTheme'

export const detalhesClassificacaoTabelaStyles = {
  Container: styled(ContainerSombra)`
    padding: 24px;
    margin-top: 16px;
    flex-grow: 1;
    position: relative;
  `,
  ContainerFormDataButton: styled.div`
    gap: 8px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  `,
  TextFieldDataFim: styled(TextField)`
    .MuiFormHelperText-root {
      position: absolute;
      margin-top: 40px;
    }
  `,
  ContainerFormulario: styled.form`
    margin-top: 51px;
  `,
  Titulo: styled(Typography)`
    display: flex;
    align-items: center;
    font-size: ${customTheme.fontSize[18]};
    font-weight: 500;
    color: ${customTheme.colors.cinza09};
  `,
  BotaoExportar: styled(DownloadRelatorio)`
    align-self: flex-end;
  `
}
