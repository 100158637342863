import { IOProps } from 'react-compose-io'
import { ControleComissaoHistoricoClassificacaoIO } from './ControleComissaoHistoricoClassificacao.IO'
import { DetalhesClassificacaoTabela } from '../../components/DetalhesClassificacaoTabela'

type ControleComissaoHistoricoClassificacaoProps = {}

export function ControleComissaoHistoricoClassificacaoView({
  _io
}: IOProps<ControleComissaoHistoricoClassificacaoIO, ControleComissaoHistoricoClassificacaoProps>) {
  return (
    <DetalhesClassificacaoTabela
      titulo="Detalhes dos historicos comissao pedido"
      managerAtualizaDetalhesFuctions={_io.managerAtualizaDetalhesFuctions}
      managerGerarRelatorioDetalhesFunction={_io.managerGerarRelatorioDetalhesFunction}
      managerObterDetalhesFunction={_io.managerObterDetalhesFunction}
    />
  )
}
