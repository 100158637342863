import { PAGES } from './../../router/routes'
import controleComissaoManager from '../../services/api/controleComissao/controleComissaoManager'

export function controleComissaoHistoricoIO() {
  return {
    managerObterFunction: controleComissaoManager.obterResumosHistoricoPedidoComissaoParceiro,
    managerAtualizarFunction: controleComissaoManager.atualizaResumosHistoricoPedidosComissao,
    managerProcessaPlanilha: controleComissaoManager.atualizarStatusComissaoPedidoMassa,
    pageDashboardFunction: PAGES.comissaoHistoricoParceiro,
    pageDetalhesFunction: PAGES.controleComissaoHistoricoClassificacao,
    voltarPage: PAGES.comissaoHistorico
  }
}

export type ControleComissaoHistoricoIO = ReturnType<typeof controleComissaoHistoricoIO>
